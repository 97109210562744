import React from "react";

import { PortableText } from "@portabletext/react";
import { graphql } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import ServiceLogo from "../../components/ServiceLogo/ServiceLogo";
import Umbrella from "../../components/Umbrella/Umbrella";
import UmbrellaContent from "../../components/UmbrellaContent/UmbrellaContent";
import { ExternalLinkIcon } from "../../icons";
import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const SolutionDetailPage = ({ data: { page } }) => (
  <MainLayout
    header={(
      <Header
        headline={page.headline}
        bgImageUrl={`linear-gradient(0deg, #12121E 0.32%, rgba(18, 18, 30, 0.18) 100%), url(${page.headerimage?.asset?.url})`}
        cta={page.showCTA && (
          <Button
            size="small"
            title={page.ctaButton?.title}
            color="white"
            variant="primary"
            href={page.ctaButton?.external}
          >
            {page.ctaButton?.title} <ExternalLinkIcon />
          </Button>
        )}
        variant="solution"
        headlineClass="text-h2"
        secondaryHeadline={page.secondaryHeadline}
        text={<PortableText value={page._rawDescription} />}
        logo={page.icon && (
          <SanityImage
            {...page.icon}
            alt={page.title}
          />
        )}
        umbrella={(
          <div className="grid grid-cols-12 lg:gap-x-30">
            <div className="col-span-12 lg:col-span-8">
              <UmbrellaContent umbrella={<Umbrella label="Delivered Services" color="white" />}>
                {page.services?.map((node) => (
                  <ServiceLogo
                    key={node.variant}
                    variant={node.variant}
                    size="small"
                  />
                ))}
              </UmbrellaContent>
            </div>
          </div>
        )}
      />
    )}
  >
    {page.contentElements && (
      <AllComponents elements={page.contentElements} />
    )}
  </MainLayout>
);
export const query = graphql`
  query ($id: String!) {
    page: sanitySolutionDetail(id: {eq: $id}) {
      id
      Metadata {
        ...MetaData
      }
      services {
        variant
      }
      title
      slug {
        current
      }
      headerimage {
        asset {
          url
        }
      }
      icon {
        ...ImageWithPreview
      }
      variant
      headline
      secondaryHeadline
      showCTA
      ctaButton {
        variant
        size
        color
        title
        external
        internalLink {
          ... on SanitySolutionDetail {
            id
            slug {
              current
            }
          }
          ... on SanityServiceDetail {
            id
            slug {
              current
            }
          }
          ... on SanityLegalDocument {
            _type
            id
            slug {
              current
            }
          }
          ... on SanityService {
            id
            name
            _type
          }
        }
      }
      _rawDescription
      contentElements {
        ...SectionHeader
        ...ServiceCardList
        ...SolutionCardList
        ...OfficeCardList
        ...ContentCardList
        ...CtaCardList
        ...ContentCardGrid
        ...CtaCard
        ...ContentModuleText
        ...ContentSection
        ...ContentModuleEmbed 
      }
    }
 }
`;

SolutionDetailPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default SolutionDetailPage;
